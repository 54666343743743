import 'stylesheets/application'
import 'controllers'
import Rails from '@rails/ujs'
import ahoy from 'ahoy.js'

Rails.start()

window.addEventListener('DOMContentLoaded', (event) => {
  ;[...document.querySelectorAll('.entry-event')].forEach(function (item) {
    item.addEventListener('click', function (e) {
      ahoy.track('Entry Click', {
        entry_id: parseInt(e.target.dataset['entryId']),
        category_id: parseInt(e.target.dataset['categoryId']),
        category_group_id: parseInt(e.target.dataset['categoryGroupId']),
        category_tag_id: parseInt(e.target.dataset['categoryTagId'])
      })
    })
  })
  ;[...document.querySelectorAll('.inbound-link')].forEach(function (item) {
    item.addEventListener('click', function (e) {
      ahoy.track('Inbound Link Click', {
        entry_id: parseInt(e.target.dataset['entryId']),
        category_id: parseInt(e.target.dataset['categoryId']),
        category_group_id: parseInt(e.target.dataset['categoryGroupId']),
        category_tag_id: parseInt(e.target.dataset['categoryTagId'])
      })
    })
  })
  ;[...document.querySelectorAll('.outbound-link')].forEach(function (item) {
    item.addEventListener('click', function (e) {
      ahoy.track('Outbound Link Click', {
        entry_id: parseInt(e.target.dataset['entryId']),
        category_id: parseInt(e.target.dataset['categoryId']),
        category_group_id: parseInt(e.target.dataset['categoryGroupId']),
        category_tag_id: parseInt(e.target.dataset['categoryTagId'])
      })
    })
  })
  ;[...document.querySelectorAll('.entry-video')].forEach(function (item) {
    let playTime = 0
    let startTime

    item.addEventListener('play', function (e) {
      startTime = new Date().getTime()
      //console.log("Play event:", {
      //  entryId: e.target.dataset["entryId"],
      //  startTime,
      //});
      ahoy.track('Video Play', {
        entry_id: parseInt(e.target.dataset['entryId'])
      })
    })

    item.addEventListener('timeupdate', function (e) {
      if (!item.paused && !item.ended && startTime) {
        const currentTime = new Date().getTime()
        playTime += currentTime - startTime
        startTime = currentTime
        //console.log("Timeupdate event:", { playTime, currentTime });
      }
    })

    const handlePauseOrEnd = function (e) {
      if (startTime) {
        const currentTime = new Date().getTime()
        playTime += currentTime - startTime
        startTime = null
        //console.log("Pause or End event:", { playTime, currentTime });
      }
      if (playTime > 0) {
        ahoy.track('Video Pause or End', {
          entry_id: parseInt(e.target.dataset['entryId']),
          play_time: playTime / 1000 // convert to seconds
        })
      }
      playTime = 0
    }

    item.addEventListener('pause', handlePauseOrEnd)
    item.addEventListener('ended', handlePauseOrEnd)
  })
  ;[...document.querySelectorAll('.cta-event')].forEach(function (item) {
    item.addEventListener('click', function (e) {
      ahoy.track('Cta Click', { cta_id: parseInt(e.target.dataset['ctaId']) })
    })
  })
  ;[...document.querySelectorAll('.badge-event')].forEach(function (item) {
    item.addEventListener('click', function (e) {
      ahoy.track('Badge Click', {
        venue_id: parseInt(e.target.dataset['venueId'])
      })
    })
  })
  ;[...document.querySelectorAll('.search-event')].forEach(function (item) {
    item.addEventListener('click', function (e) {
      ahoy.track('Search Click')
    })
  })
})
