import { Controller } from "stimulus"

export default class extends Controller {

  connect() {}

  toggle(event) {
    const targetId = event.currentTarget.dataset.modalId
    const modal = document.getElementById(targetId);
    modal.classList.toggle("hidden");
  }

}
