import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "toggleButton",
    "expandedContent",
    "toggleTab",
    "partialToUpdate",
    "submitButton",
  ];

  connect() {
    if (this.hasExpandedContentTarget) {
      this.expandedContentTarget.classList.add("translate-y-full");
      this.toggleTabTarget.classList.remove("translate-y-full");

      setTimeout(() => {
        this.showCta();
      }, 1500);

      this.handleScroll = this.handleScroll.bind(this);
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  disconnect() {
    if (this.hasExpandedContentTarget) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  updateHtml(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;
    this.renderRecaptcha();
  }

  handleSuccess() {
    window.location.reload();
  }

  renderRecaptcha() {
    const recaptchaElement = document.querySelector(".g-recaptcha");
    if (recaptchaElement) {
      const siteKey = recaptchaElement.getAttribute("data-sitekey");
      if (window.grecaptcha && siteKey) {
        window.grecaptcha.render(recaptchaElement, {
          sitekey: siteKey,
        });
      }
    }
  }

  handleScroll() {
    const quarterPage = document.documentElement.scrollHeight * 0.25;
    const currentScroll = window.scrollY + window.innerHeight;

    if (
      currentScroll > quarterPage &&
      this.expandedContentTarget.classList.contains("translate-y-0")
    ) {
      this.hideCta();
    }
  }

  showCta() {
    this.toggleTabTarget.classList.add("translate-y-full");

    setTimeout(() => {
      this.expandedContentTarget.classList.remove("translate-y-full");
      this.expandedContentTarget.classList.add("translate-y-0");
    }, 300);
  }

  hideCta() {
    this.expandedContentTarget.classList.remove("translate-y-0");
    this.expandedContentTarget.classList.add("translate-y-full");

    setTimeout(() => {
      this.toggleTabTarget.classList.remove("translate-y-full");
    }, 300);
  }

  toggleCta() {
    if (this.expandedContentTarget.classList.contains("translate-y-0")) {
      this.hideCta();
    } else {
      this.showCta();
    }
  }

  toggleAll(event) {
    const checkboxes = this.element.querySelectorAll(".entry-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });
  }

  disableButton() {
    const button = this.submitButtonTarget;
    button.disabled = true;
    button.classList.add("opacity-50", "cursor-not-allowed");
  }

  enableButton() {
    const button = this.submitButtonTarget;
    button.disabled = false;
    button.classList.remove("opacity-50", "cursor-not-allowed");
  }
}
